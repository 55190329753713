<template>
  <div class="px-3 xl:pl-0">
    <p
      v-if="data.title"
      :class="[
        'font-bold',
        buttonActions({
          alignedContent: data.alignedContent?.name
        })
      ]"
      data-testid="button-actions-title"
    >
      {{ data.title }}
    </p>

    <div
      class="buttons-actions flex flex-wrap items-center gap-1"
      :class="
        buttonActions({
          layout: data.layout,
          alignedButtons: data.alignedContent?.name
        })
      "
      data-testid="button-actions-btns"
    >
      <elements-button
        v-for="button in data.buttons"
        :key="button.url"
        v-bind="button"
      >
        {{ button.text }}
      </elements-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiSectionsButtonActions } from '@autobid/strapi-integration/typescript/strapi/sections/SectionsButtonActions'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { buttonActions } from '@autobid/ui/utils/cva/buttonActionsCva'

interface Props {
  data: StrapiComponentProps<StrapiSectionsButtonActions>
}

defineProps<Props>()
</script>
